import React from "react"
import { Helmet } from "react-helmet";

import "./style.css";



export default function Home() {
  return <main>
    <Helmet>
      <title>Anthos Digital</title>
    </Helmet>

    <footer>
      <address>
        Anthos Digital bv
      </address>
    </footer>
  </main>
}
